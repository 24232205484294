import i18n from '../../../../i18n';

export const makeFieldsRoulette = () => {
  return {
    [i18n.t('transactions.roundID')]: 'identifierNumber',
    [i18n.t('transactions.roundCode')]: 'roundId',
    [i18n.t('tableReports.client')]: 'client',
    [i18n.t('tableReports.operator')]: 'operator',
    [i18n.t('tableReports.userId')]: 'playerId',
    [i18n.t('betDetail.user')]: 'player',
    WL: 'WL',
    [i18n.t('tableReports.casinoName')]: 'roulette',
    [i18n.t('tableReports.transactionType')]: 'type',
    [i18n.t('tableReports.debit')]: 'debit',
    [i18n.t('tableReports.credit')]: 'credit',
    [i18n.t('tableReports.currency')]: 'currency',
    [i18n.t('tableReports.balance')]: 'userBalance',
    [i18n.t('tableReports.transactionId')]: 'transactionId',
    [i18n.t('tableReports.error')]: 'errorRound',
    [i18n.t('tableReports.rollback')]: 'rollbackRound',
    [i18n.t('tableReports.retry')]: 'retryRound',
    [i18n.t('tableReports.date')]: 'createdAt',
    Ip: 'playerIp',
    [i18n.t('tableReports.country')]: 'playerCountry',
    [i18n.t('tableReports.deviceType')]: 'platform',
    'User agent': 'userAgent',
  };
};

export const makeFieldsExternal = () => {
  return {
    'ID JUGADOR': 'player.userId',
    JUGADOR: 'player.username',
    JUEGO: 'game',
    TIPO: 'type',
    MONTO: 'amount',
    'MONTO USD': 'amountExchangeDollar',
    'CAMBIO USD': 'currencyExchangeDollar',
    RONDA: 'round',
    FECHA: 'createdAt',
    WL: 'WL',
  };
};
